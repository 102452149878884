import * as React from 'react';
import { Fabric } from '@fluentui/react/lib/Fabric';
import { Provider as ReduxProvider } from 'react-redux';
import { useState, useEffect } from 'react';
import { useTeams } from 'msteams-react-base-component';
import { createBrowserHistory } from 'history';
import * as microsoftTeams from '@microsoft/teams-js';
import jwtDecode from 'jwt-decode';
import {
    Provider,
    Flex,
} from '@fluentui/react-northstar';

import CommunityForm from './components/CommunityForm';
import AuthContext from '../contexts/Auth';
import configureStore from '../store/configureStore';
import { Progress } from './components/Progress';

/**
 * Fabric UI
 */
import config from '../config';

export const history = createBrowserHistory();

export const store = configureStore(history);

/**
 * Implementation of the Createacommunity content page
 */
export const CreateCommunityTab = () => {
    const [{ inTeams, theme, context }] = useTeams();
    const [, setEntityId] = useState<string | undefined>();
    const [, setName] = useState<string>();
    const [, setError] = useState<string>();
    const [accessToken, setAccessToken] = useState<string>('');
    const [memberType, setMemberType] = useState<string>();

    // eslint-disable-next-line
    const authContext = React.useContext(AuthContext);

    const tokenSettings = {
        teamsToken: accessToken,  //current teams access token that we can use to exchange for an API access token
        setTeamsToken: setAccessToken    //NOT USED: pass in setter method to allow us to refresh the teams access token in a component if required
    };

    useEffect(() => {
        if (inTeams === true) {
            microsoftTeams.authentication.getAuthToken({
                successCallback: (token: string) => {
                    const decoded: { [key: string]: any; } = jwtDecode(token) as { [key: string]: any; };
                    setName(decoded!.name);
                    //exchangeClientTokenForGTToken(token);
                    setAccessToken(token);
                    microsoftTeams.appInitialization.notifySuccess();
                },
                failureCallback: (message: string) => {
                    setError(message);
                    microsoftTeams.appInitialization.notifyFailure({
                        reason: microsoftTeams.appInitialization.FailedReason.AuthFailed,
                        message
                    });
                    console.log('Teams authentication failed.');
                    console.log(message);
                },
                resources: [config.tabAppUri as string]
            });
        } else {
            setEntityId('Not in Microsoft Teams');
        }
    }, [inTeams]);

    useEffect(() => {
        if (context) {
            console.log('GovTEAMS Create Community Teams App v1.0.240528')
            setEntityId(context.entityId);
            setMemberType(context.userPrincipalName?.toString().replace(/.*@/, "").split('.')[0].toUpperCase())
        }
    }, [context]);

    /**
     * The render() method to create the UI of the tab
     */
    /*  padding-top
        padding-right 
        padding-bottom cs
        padding-left */
    return (
        <Provider theme={theme}>
            <ReduxProvider store={store}>
                <AuthContext.Provider value={tokenSettings}>
                    <Fabric>
                        <Progress />
                        <Flex fill={false} column className='container' styles={{
                            padding: '.8rem 1rem .8rem 1rem',
                        }}>
                            <Flex.Item>
                                <CommunityForm teamsToken={accessToken} memberType={memberType} />
                            </Flex.Item>
                        </Flex>
                    </Fabric>
                </AuthContext.Provider>
            </ReduxProvider>
        </Provider>
    );
};
